.cart .shopping-cart{
    background-color: var(--white);
    margin-bottom: 2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    overflow-x:auto;
    overflow-y:hidden;  
} 
 
.cart .shopping-cart .container{
    min-width: 90rem; 
}

.cart .title{
    background-color: var(--secondary-color);
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
}

.cart .title h3{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    font-size: 1.8rem;
    text-align: center;
}

.cart .box-container{
    padding: 1rem;
}

.cart .cart-total{
    width: 40rem;
    background-color: var(--white);
    padding: 1rem;
    margin-left: auto;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-top: 0.3rem solid var(--secondary-color);
}

.cart .cart-total .btn{
    width: 100%;
    text-align: center;
    margin: 1rem 0;
}
