.page-title{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/PageTitle/PageTitle.png");
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/PageTitle/PageTitle.png");
    min-height: 40rem;
    background-repeat: no-repeat;  
    background-size: cover; 
    background-position: center; 
    width: 100%; 
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.page-title .title h2{
    color: var(--white);
    font-size: 5rem;
    font-weight: 400;
    padding-top: 4rem;
    text-transform: uppercase;
}

.page-title .link{
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 400;
    gap: 1rem;
    color: var(--white);
}

.page-title .link a{
    font-size: 2.5rem;
    color: var(--white);
}

.page-title .link a:hover{
    color: var(--secondary-color);
}

.page-title .link span{
    font-size: 2.5rem;
    color: var(--secondary-color);
}


