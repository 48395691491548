/*--------------------- 0- Global CSS (Start) ---------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
  --main-color: #F24C4C;
  --secondary-color: #293462;
  --black: #000;
  --white: #fff;
  --grey: #666; 
  --border: 0.1rem solid rgba(0, 0, 0, 0.1);
  --border-radius: 1rem;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

*{
  font-family: 'Roboto', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: capitalize;
  border: none;
  outline: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

*::selection{
  color: var(--white);
  background-color: var(--main-color);
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body{
  background-color: rgba(41, 52, 98, 0.05);
}

section{
  padding: 2rem 5%;
}

::-webkit-scrollbar{
  width: 0.8rem;
}

::-webkit-scrollbar-thumb{
  border-radius: 1rem;
  background-color: var(--main-color);
}

::-webkit-scrollbar-track{
  background: var(--secondary-color);
}

/*----- Form CSS -----*/
.form{
  padding: 2rem;
  background: var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  margin: 1rem auto;
  text-align: center;
  border: 0.1rem solid var(--secondary-color);
}

.form h3{ 
  font-size: 2.5rem;
  font-weight: 500;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  color: var(--secondary-color);
  text-transform: uppercase;
  text-align: center;
  border-bottom: 0.1rem solid var(--secondary-color);
}

.form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.form .input-box .box{
    width: 49%;
}

.form .box {
  width: 100%;
  padding: 1.2rem 0.8rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  color: var(--grey);
  text-transform: none;
  margin: 0.7rem 0;
}

.form .box::placeholder{
  text-transform: capitalize;
}

.form .box:focus{
  border-color: var(--main-color);
}

.form p {
  padding-top: 2rem;
  font-size: 1.5rem;
  color: var(--grey);
  text-align: center;
  padding-bottom: 1rem;
}

.form button{
  width: 100%;
  margin: 1rem 0;
  background-color: transparent;
}

.form button .btn{
  width: 100%;
  border-radius: 5rem;
  overflow: hidden;
}


/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
  html{
    font-size: 55%;
  }

  .blog.grid, .blog.list{
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

}

@media (max-width: 768px){

  section, header, .footer{
    padding: 2rem;
  }

  .contact > .box-container{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .shop{
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .contact-info{
    margin: 0 5rem;
    width: 80%;
    -webkit-transform: translate(0, 5rem);
            transform: translate(0, 5rem);
  }

  .contact-form{
    padding: 2rem;
    padding-top: 8rem;
  }

}

@media (max-width: 450px){

  html{
      font-size: 50%;
  }

  section, header, .footer{
      padding: 1rem;
  }

  .home-item .content{ 
    padding: 2rem 14%;
  }

  .contact-form .input-box{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .form .input-box .box{
    width: 100%;
  }

  .cart-total{
    width: 100%;
  }

  .checkout .address form .input-box .box{
    width: 100%;
  }

  .leave-reply form .input-box .box{
    width: 100%;
  }

  .edit-address form .box {
    width: 100%;
  }

}

/*--------------------- Responsive (End) ---------------------*/
