.controls .button{
    display: inline-block;
    pointer-events: auto;
	cursor: pointer;
	padding: 0.7rem 1rem;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-size: 1.8rem;  
	color: var(--main-color); 
}

.controls .button:hover,
.controls .button.active{
	color: var(--secondary-color);
}

._3bkri {
    background-color: var(--main-color) !important;
}

._20cg_ {
	background-color: var(--main-color) !important;
}

._20cg_:hover {
	background-color: var(--main-color) !important;
}

._20cg_:focus {
	-webkit-box-shadow: 0px 0px 0px 3px var(--secondary-color) !important;
			box-shadow: 0px 0px 0px 3px var(--secondary-color) !important;
}
