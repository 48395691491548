@import "~react-image-gallery/styles/css/image-gallery.css";
.product-details .product-des{
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;  
        flex-wrap: wrap;
    -webkit-box-pack: center; 
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8rem;
    background-color: var(--white);
    padding: 2rem;
    margin-bottom: 2rem;
}

.product-details .product-des .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details .product-des .image-gallery-slide-wrapper .main{
    width: 40rem;
    height: 40rem;
}

.product-details .product-des .image .image-gallery-slide-wrapper img.image-gallery-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-details .product-des .image .change-btns{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
}

.product-details .product-des .image .change-btns img{
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    border: 0.2rem solid var(--black);
    padding: 1rem 0.5rem; 
    margin: 0.5rem 0;
    cursor: pointer;

}

.product-details .product-des .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details .product-des .content h3{
    font-size: 3rem;
    font-weight: 500;
    color: var(--black);
}

.product-details .product-des .content .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 1rem 0;
}

.product-details .product-des .content .intro .rating{
    color: var(--main-color);
    font-size: 2rem;
}

.product-details .product-des .content .intro span{
    font-size: 2rem;
    padding-left: 1rem;
}

.product-details .product-des .content p{
    font-size: 1.5rem;
    padding-top: 0.5rem;
    line-height: 1.5;
    font-weight: 400;
    color: #666;
}

.product-details .product-des .content .price{
    font-size: 2rem;
    font-weight: bold;
    color: var(--main-color);
}

.product-details .product-des .content .price span{
    color: #666;
    font-weight: lighter;
    text-decoration: line-through;
}

.product-details .product-des .content .btn{
    margin: 1rem 0;
    margin-bottom: 3rem;
}

.product-details .availablity,
.product-details .qty{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
    width: 20rem;
}

.product-details .qty .text{
    width: 10rem;
}

.product-details h4{
    font-size: 1.8rem;
    color: var(--black);
    font-weight: 500;
}

.product-details .availablity h4,
.product-details .qty h4{
    font-size: 1.8rem;
    color: var(--black);
    font-weight: 500;
}

.product-details .availablity span{
    font-size: 1.6rem;
    color: red;
    padding: 0 0.5rem;
}

.product-details .categories,
.product-details .tags{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
}

.product-details .categories h4,
.product-details .tags h4{
    font-size: 1.8rem;
    color: var(--black);
    font-weight: 500;
}

.product-details .categories span,
.product-details .tags span{
    font-size: 1.6rem;
    color: var(--secondary-color);
    padding: 0 0.5rem;
}


/*----- 2- Related Products -----*/
.related-items .box-container{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1rem;
}



/*--------------- Product Gallery ---------------*/

/*----- Buttons -----*/
.image-gallery-icon:hover {
    color: var(--main-color);
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 6rem;
    width: 6rem;
}

.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 0;
    display: none;
}

.image-gallery-play-button {
    display: none;
}

.image-gallery-fullscreen-button {
    display: none;
}

/*----- Main Image -----*/
.image-gallery-slide-wrapper{
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    overflow: hidden;
    height: 40rem;
}

.image-gallery-slide-wrapper:hover .image-gallery-left-nav, 
.image-gallery-slide-wrapper:hover .image-gallery-right-nav,
.image-gallery-slide-wrapper:hover .image-gallery-play-button,
.image-gallery-slide-wrapper:hover .image-gallery-fullscreen-button{
    display: block;
}


/*----- Thumbnails -----*/
.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    -webkit-transition: border .3s ease-out;
    -moz-transition: border .3s ease-out;
    -o-transition: border .3s ease-out;
    transition: border .3s ease-out;
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    overflow: hidden; 
    background: transparent;
    padding: 0;
}

.image-gallery-thumbnail:hover,
.image-gallery-thumbnail.active {
    border-color: var(--main-color);
}



