.faq .accordion {
    background: var(--white);
    margin-bottom: 1.5rem;
    -webkit-box-shadow: var(--box-shadow); 
            box-shadow: var(--box-shadow);
    overflow: hidden;
    border-bottom: 0.1rem solid var(--secondary-color);
} 
 
.faq .accordion:last-child {
    margin-bottom: .5rem;
}  

.faq .accordion .accordion-heading {
    padding: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center; 
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 1rem;
    background: var(--secondary-color); 
    cursor: pointer;
}

.faq .accordion.active .accordion-heading {
    background-color: var(--main-color);
}

.faq .accordion .accordion-heading h3 {
    font-size: 2rem;
    color: var(--white);
}

.faq .accordion .accordion-heading .icon {
    font-size: 2rem;
    color: var(--white);
}

.faq .accordion-content {
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
    display: block;
}
