.blog-info{
  background-color: var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  position: relative;
} 
 
.blog-info .image{ 
  height: 40rem; 
}

.blog-info .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-info .content{
  padding: 2rem;   
}

.blog-info .details .label{
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  padding: 0.8rem 1.5rem;
}

.blog-info .details .date{
  font-size: 1.8rem;
  color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.blog-info .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--secondary-color);
  padding-bottom: 1rem;
}

.blog-info p{
  padding-bottom: 2rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}
 
.blog-info .important{
    padding: 2rem 4rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
    font-style: italic;
    line-height: 1.8;
    border-left: 0.2rem solid var(--secondary-color);
    margin-bottom: 2rem;
    margin-left: 1rem;
    background: -webkit-linear-gradient(rgba(41, 52, 98, 0.2), rgba(41, 52, 98, 0.2)), url("../../assets/Blog/blogqoute.png");
    background: linear-gradient(rgba(41, 52, 98, 0.2), rgba(41, 52, 98, 0.2)), url("../../assets/Blog/blogqoute.png");
    background-position: center;
}

.blog-info .blog-gallery{ 
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1rem;
  padding-bottom: 2rem;
}

.blog-info .blog-gallery img{
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.blog-info .end-details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

.blog-info .tags, 
.blog-info .share{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.blog-info .share h3{
    color: var(--black);
    font-size: 1.4rem;
    padding-right: 1rem;
}

.blog-info .tags span{
    color: var(--grey);
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.blog-info .share .icon{
    color: var(--grey);
    font-size: 1.6rem;
}

.blog-info .share .icon:hover{
    color: var(--main-color);
    cursor: pointer;
}

  