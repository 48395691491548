.pricing .box-container{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 2rem;
}

.price-item{ 
  -webkit-box-flex: 1; 
  -ms-flex:1 1 33rem;  
      flex:1 1 33rem;
  background-color: var(--white);
  padding: 2rem;
  border: var(--border);
  border-radius: var(--border-radius);
  overflow: hidden;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.price-item h3{
  color: var(--black);
  font-size: 3.5rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.price-item p{
  color: var(--grey);
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
}

.price-item .price{
  color: var(--secondary-color);
  font-size: 6rem;
  font-weight: 400;
  padding: 1.5rem 0;
}
  
.price-item .price span{
  font-size: 2rem;
  font-weight: normal;
  color: var(--grey);
}
  
.price-item ul{ 
  list-style: none;
  padding-top: 3rem;
}
  
.price-item ul li{
  padding: 0.9rem 0;
  font-size: 1.7rem;
  color: var(--grey);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  gap: 0.5rem;
}

.price-item ul li .icon{
  color: var(--main-color);
  font-size: 2rem;
}

.price-item .btn{
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}


  
  