.profile .heading{
    text-align: left;
}

.profile-item{ 
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);  
    padding: 2rem;
}

.profile .box-container{ 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
        align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.profile .profile-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem;
        flex: 1 1 25rem;
}
 
.profile .profile-details .content{
    text-align: center;
}

.profile .profile-details .content img{
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.profile .profile-details .info .box{
    margin-bottom: 1rem;
}

.profile .profile-details .content h3{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--secondary-color);
    padding-top: 2rem;
}

.profile .profile-details .content p{
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--grey);
}

.profile .profile-details .content .gmail{
    text-transform: none;
}

.profile .address-details{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50rem;
        flex: 1 1 50rem;
    background-color: var(--white);;
    padding: 1rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.profile .address-details .address{
    margin-bottom: 1rem;
}

.profile .btn{
    margin-top: 2rem;
}
