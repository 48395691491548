.btn {
	position: relative;
	display: inline-block;
	border: 0.2rem solid var(--secondary-color);
	font-family: 'Lato', sans-serif;
	pointer-events: auto;
	cursor: pointer; 
	padding: 1.5rem 3rem; 
	font-size: 1.8rem;
	font-weight: 500;
	font-style: italic; 
	background: var(--secondary-color);
	color: var(--white);
	z-index: 1;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.btn:after {
	position: absolute;
	content: "";
	width: 0;
	height: 100%;
	top: 0;
	right: 0;
	z-index: -1;
	background: #e0e5ec;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.btn:hover {
	color: var(--secondary-color);
}

.btn:hover:after {
	left: 0;
	width: 100%;
}

.btn:active {
	top: 2px;
}
  