.about{
    padding: 2rem 0;
} 

.about .box-container{
    background-color: rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center; 
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 3rem; 
    padding: 0 5%;
}

.about .image{
    -webkit-box-flex: 1;
    -ms-flex:1 1 40rem;
        flex:1 1 40rem;
    position: relative;
}

.about .image img{
    height: 50rem;
    width: 100%;
    object-fit: cover;
}

.about .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
    height: 80%;
    padding: 2rem 0;
}
 
.about .content h2{
    color: var(--secondary-color);
    font-size: 4rem;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;
}

.about .content h5{
    color: var(--black);
    font-size: 1.8rem;
    line-height: 1.5;
    padding-bottom: 2rem;
}

.about .content > p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.6;
    padding-bottom: 1rem;
}

.about .features{
    list-style-type: none;
    padding-bottom: 3rem;
}

.about .features li{
    font-size: 1.6rem;
    color: var(--grey);
    padding: 0.5rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
}

.about .features .icon{
    color: var(--main-color);
    font-size: 1.6rem;
}