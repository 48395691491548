.blog-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white); 
  position: relative;
}   

.blog-item .image{ 
  height: 25rem;
}

.blog-item .image img{
  height: 100%;
  width: 100%; 
  object-fit: cover;
}

.blog-item .content{
  padding: 2rem 1rem; 
}

.blog-item .details .label{
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1.6rem;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
}

.blog-item .details .date{
  font-size: 1.8rem;
  color: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.blog-item .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.blog-item .content .main-heading:hover{
  color: var(--main-color);
}

.blog-item .content p{
  padding-top: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}

