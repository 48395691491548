.contact{
    padding: 2rem 0;
}

.contact iframe{
    width: 100%;
    height: 30rem;
    margin-bottom: 2rem;
} 

.contact h3{  
    font-size: 3rem;
    font-weight: 500;
    padding-bottom: 1rem;
}

.contact > .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0rem 5%;
}

.contact-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
    padding: 2rem;
    background-color: var(--secondary-color);
    -webkit-transform: translateX(5rem);
            transform: translateX(5rem);
    height: 80%;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.contact-info h3{
    color: var(--white);
}

.contact-info .info-item{
    padding: 1rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
        align-items: flex-start;
    gap: 1.5rem;
    width: 100%;
}

.contact-info .info-item .icon{
    font-size: 2rem;
    color: var(--white);
    padding-top: 0.5rem;
}

.contact-info .info-item .content p{
    font-size: 1.8rem;
    color: var(--white);
    line-height: 1.6;
    text-transform: none;
}

.contact-form{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50rem;
        flex: 1 1 50rem;
    background-color: var(--white);
    padding: 2rem;
    padding-left: 8rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border: var(--border);
}

.contact-form h3{
    color: var(--black);
}

.contact-form .box{
    width: 100%;
    text-transform: none;
    color: var(--grey);
    font-size: 1.6rem;
    border-bottom: 0.1rem solid var(--secondary-color);
    padding: 1.5rem 0.5rem;
    margin: 0.5rem 0;
}

.contact-form .box:focus{
    border-color: var(--main-color);
}

.contact-form .box::placeholder{
    color: var(--grey);
    text-transform: capitalize;
}

.contact-form textarea{
    height: 15rem;
    resize: none;
}

.contact-form button{
    margin-top: 1rem;
}

.contact .alert{
    font-size: 2rem;
    color: var(--main-color);
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 1rem;
}

.contact .msg-alert{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1rem;
}