.testimonials{
    overflow: hidden;
}  

.testimonial-slider{ 
    overflow: hidden;
    position: relative;
}

.testimonial-slider .swiper{
    overflow: visible !important;
    padding-bottom: 5rem; 
}

.testimonials .swiper-pagination-bullet{
    height: 1rem;
    width: 4rem; 
    border-radius: 50rem;
    background: var(--main-color);
    opacity: 0.7;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}
 
.testimonials .swiper-pagination-bullet-active{
    background: var(--secondary-color);
    opacity: 0.9;
}

.testi-item{
    border-top-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    background-color: var(--white);
    overflow: hidden;
    padding: 2rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    position: relative;
}

.testi-item  .quote-left{
    position: absolute;
    top: 2rem;
    right: 2rem; 
    font-size: 6rem;
    color: var(--main-color);
    opacity: 0.4;
}

.testi-item .content{
    padding: 3rem 0;
}

.testi-item .comment{
    padding: 1rem 0;
    position: relative;
}

.testi-item .quote-start,
.testi-item .quote-end{
    position: absolute;
    font-size: 2.5rem;
    color: var(--secondary-color);
}

.testi-item .comment .quote-start{
    top: 0;
    left: 0;
}

.testi-item .comment .quote-end{
    right: 0;
    bottom: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.testi-item .comment p{
    color: var(--grey);
    font-size: 1.8rem;
    line-height: 1.5;
    margin-top: 2rem;
}

.testi-item .intro{
    position: relative;
}

.testi-item img{
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
}

.testi-item .intro h3{
    font-size: 2.2rem;
    color: var(--secondary-color);
    padding-bottom: 0.5rem;
}

.testi-item .intro h5{
    color: var(--main-color);
    font-size: 1.6rem;
    font-weight: 400;
}

