.services .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1.5rem;
}
  
.service-item{ 
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
        flex: 1 1 33rem;
    background-color: var(--white);
    text-align: center;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.service-item .image img{
    height: 25rem;
}

.service-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.service-item .content{
    padding: 2rem;
}

.service-item a{
    display: block;
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-color);
    padding-bottom: 1.5rem;
}

.service-item a:hover{
    color: var(--main-color);
}

.service-item p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.6;
}
