.order-items .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center; 
    padding-bottom: 1rem; 
}

.order-items .item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem; 
    text-align: center;
    font-size: 2rem;
    font-weight: 400; 
    color: var(--black);
}

.order-items .item .name{
    font-weight: 500;
    color: var(--secondary-color);
}

.order-items .item img{
    height: 15rem;
    width: 15rem;
    object-fit: cover;
    background-color: #f7f7f7;
}




