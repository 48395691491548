.product-item{
    position: relative;
    overflow: hidden;
    -webkit-transition: all 1s ease;  
    -moz-transition: all 1s ease; 
    -o-transition: all 1s ease;  
    transition: all 1s ease; 
    background-color: var(--white);;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
} 
  
.product-item .options{
    position: absolute;
    top: 2rem;
    right: -100%; 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    gap: 1rem;
}

.product-item .options a{
    height: 4rem;
    width: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    background: var(--white);
    color: var(--secondary-color);
    cursor: pointer;
}

.product-item .options a .icon{ 
    height: 2rem;
    font-size: 3rem;
}

.product-item .options a:hover{
    background-color: var(--main-color);
}

.product-item .options a:hover .icon{
    color: var(--white);
}

.product-item .image{
    position: relative;
    overflow: hidden;
}

.product-item .image img{
    width: 100%;
    height: 25rem;
    object-fit: cover;
}

.product-item .content{
    padding: 2rem;
    text-align: center;
}

.product-item h3{
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-color);
}

.product-item h3:hover{
    color: var(--main-color);
}

.product-item .rating{
    font-size: 1.6rem;
    color: var(--main-color);
    padding: 0.5rem 0;
}

.product-item .price{
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--main-color);
}

.product-item .price span{ 
    font-size: 1.4rem;
    font-weight: 400;
    text-decoration: line-through;
    color: var(--grey);
    padding-left: 0.2rem;
}

.product-item:hover .options{
    right: 2rem;
}
