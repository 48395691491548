.logo{ 
    display: -webkit-box;  
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 1rem;
}

.logo img{
    width: 5rem;
    object-fit: cover;
}

.logo .text{
    text-align: right;
}

.logo h3{ 
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--secondary-color);
    text-transform: capitalize;
    letter-spacing: 0.1rem;
}

.logo p{ 
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--main-color);
    text-transform: capitalize;
}
