.team .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem;
  }  
  
.team-item {
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; 
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    position: relative;
}

.team-item .image {
  height: 35rem;
  overflow: hidden;
}

.team-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-item .content{
  padding: 2rem;
  background-color: var(--white);
  z-index: 2;
}

.team-item:hover .content{
  -webkit-transform: translateY(-5rem);
          transform: translateY(-5rem);
}
  
.team-item .content h3 {
    font-size: 2.5rem;
    font-weight: 500;
    padding-bottom: 00.5rem;
    color: var(--secondary-color);
  }

.team-item .content p{
  font-size: 1.8rem;
  color: var(--main-color);
}
  
.team-item .icon-container {
  position: absolute;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 1rem;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

}
  
.team-item .icon-container a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;  
  font-size: 2rem;
  color: var(--white);
  background-color: var(--secondary-color);
  padding: 1.5rem;
  width: 100%;
}

.team-item .icon-container a:hover{
  color: var(--white);
  background-color: var(--main-color);
  cursor: pointer;
}
  