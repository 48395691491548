.mobile-navbar .main-nav-link{
    position: relative; 
} 

.mobile-navbar .main-nav-link .nav-btn{
    border-bottom: 0.1rem solid var(--white);
    background-color: var(--main-color);
    color: var(--white);
    font-size: 2rem;
    cursor: pointer; 
    width: 100%;
    padding: 2rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify; 
    justify-content: space-between; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.mobile-navbar .main-nav-link .nav-btn:hover{
    background-color: var(--secondary-color);
}

.mobile-navbar .main-nav-link .icon{
    position: absolute;
    top: 50%;
    right: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: var(--white);
    font-size: 2rem;
    cursor: pointer;
}

.mobile-navbar .sub-nav-link {
    padding-left: 2rem;
    padding-bottom: 1rem;
    background: var(--white);
}

.mobile-navbar .sub-nav-link a{
    color: var(--secondary-color);
    display: block;
    padding: 1rem 0rem;
    font-size: 1.8rem;
    border-bottom: 0.1rem solid var(--secondary-color);
}

.mobile-navbar .sub-nav-link a:hover{
    color: var(--main-color);
}










