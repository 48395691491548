.heading{ 
  padding-bottom: 3rem;
  text-align: center;
}

.heading h1{ 
  font-weight: 400;
  font-size: 4rem;  
  color: var(--main-color);
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.heading h1 span {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  line-height: 2em;
  padding-left: 0.25em;
  color: var(--secondary-color);
}
