.shop-sidebar{
    padding-top: 2rem;
    -webkit-box-flex: 1;
    -ms-flex:1 1 25rem;
        flex:1 1 25rem;
} 
  
.shop-sidebar .sidebar-item{
    padding: 0rem 1.5rem; 
    margin-bottom: 2.5rem;
}

.shop-sidebar .box-container{
    padding: 1rem 0; 
} 

/*-- 1- Category --*/
.shop-sidebar .category .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 0;
}

.shop-sidebar .category .item a{
    font-size: 2rem;
    color: var(--secondary-color);
    font-weight: 400;
    cursor: pointer;
}

.shop-sidebar .category .item a:hover{
    color: var(--main-color);
}

.shop-sidebar .category .item p{
    font-size: 1.8rem;
    color: var(--main-color);
}
  
/*-- 3- Popular Products --*/
.shop-sidebar .product-item .options{
    display: none;
}

.shop-sidebar .product-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-bottom: 1rem;
    text-align: left;
}

.shop-sidebar .product-item .content{
    padding: 0.5rem;
    text-align: left;
}

.shop-sidebar .product-item .image img{
    height: 10rem;
    width: 10rem;
}

.shop-sidebar .product-item h3{
    font-size: 2rem;
}

.shop-sidebar .product-item .rating{
    font-size: 1.7rem;
}

.shop-sidebar .product-item .price{
    font-size: 1.7rem;
}

.shop-sidebar .product-item .price span{
    font-size: 1.3rem;
}
